const unauthorizedMessage = "Your login has expired. Please sign in again."

const defaultSwatches = [
  // // "#4D4D4D",
  // // "#999999",
  // // "#FFFFFF",
  // // "#F44E3B",
  // // "#FE9200",
  // // "#FCDC00",
  // // "#DBDF00",
  // // "#A4DD00",
  // // "#68CCCA",
  // // "#73D8FF",
  // // "#AEA1FF",
  // // "#FDA1FF",
  // "#333333",
  // "#808080",
  // "#cccccc",
  // "#D33115",
  // "#E27300",
  // "#FCC400",
  // "#B0BC00",
  // "#68BC00",
  // "#1E6318",
  // "#16A5A5",
  // "#009CE0",
  // "#0004BC",
  // "#7B64FF",
  // "#FA28FF",
  // "#000000",
  // // "#666666",
  // // "#B3B3B3",
  // // "#9F0500",
  // // "#C45100",
  // // "#FB9E00",
  // // "#808900",
  // // "#194D33",
  // // "#0C797D",
  // // "#0062B1",
  // // "#653294",
  // // "#AB149E",

  "#f27405",
  "#f24405",
  "#f20505",
  "#b00be5",
  "#f22e62",
  "#1875d3",
  // "#079dd9",
  "#07b2d9",
  "#025159",
  "#03a696",
  "#13AC04",
  "#66cc00",
  // "#90d948",
  "#f2ce16",
  "#cccccc",
  "#666666",

  "#000000",
  "#ffffff",
]

module.exports = {
  unauthorizedMessage,
  defaultSwatches,
}
