let ENDPOINTMKT = process.env.GATSBY_API_URL_MKT

let ENDPOINTWEB = process.env.GATSBY_API_URL_WEB
let ENDPOINTIMG = process.env.GATSBY_API_URL_IMG
// window.LOG_LEVEL = "DEBUG"
console.log("**identityID")
console.log(`us-east-1:${process.env.COGNITO_S3_IDENTITY}`)
export const amplifyconfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: `us-east-1:${process.env.COGNITO_S3_IDENTITY}`,

    // REQUIRED - Amazon Cognito Region
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.COGNITO_POOL_ID,

    userPoolWebClientId: process.env.COGNITO_APPCLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "Storylavamkt",
        endpoint: ENDPOINTMKT,
      },
      {
        name: "Storylavaweb",
        endpoint: ENDPOINTWEB,
      },
      {
        name: "Storylavaimg",
        endpoint: ENDPOINTIMG,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: process.env.S3BUCKETMEDIA, //REQUIRED -  Amazon S3 bucket name
      // identityPoolId: `us-east-1:${process.env.COGNITO_S3_IDENTITY}`,
      region: "us-east-1",
      // region: 'XX-XXXX-X', //OPTIONAL -  Amazon service region
    },
  },
}
