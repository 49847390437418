import Amplify from "@aws-amplify/core"

import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"
import Storage from "@aws-amplify/storage"

import { v4 as uuidv4 } from "uuid"

import { amplifyconfig } from "../utils/config"
import { navigate } from "@reach/router"

import { logoutUser } from "./userManagement"
import { unauthorizedMessage } from "../utils/constants"

//Configure
Amplify.configure(amplifyconfig)

// const AWS = require("aws-sdk/global") // eslint-disable-line import/no-extraneous-dependencies
// AWS.config.region = "us-east-1" // Region
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: `us-east-1:${process.env.COGNITO_S3_IDENTITY}`,
// })

// const S3 = require("aws-sdk/clients/s3")
// const s3 = new S3()

export function updateCanvasImageLoading(val) {
  return (dispatch) => {
    dispatch({
      type: "LOADING_CANVAS_IMAGE",
      data: val,
    })
  }
}
export function updatePublisherField(prop, val) {
  return (dispatch) => {
    console.log("**actions - updatePublisherField - START")
    console.log("**actions - updatePublisherField - prop")
    console.log(prop)
    console.log("**actions - updatePublisherField - val")
    console.log(val)
    dispatch({
      type: "PUBLISHER_FIELD_UPDATE",
      data: { prop, val },
    })
  }
}
export function updateFormsField(prop, val) {
  return (dispatch) => {
    // console.log("**updateFormsField")
    // console.log(val)
    dispatch({
      type: "FORMS_FIELD_UPDATE",
      data: { prop, val },
    })
  }
}
export function updateActiveToolField(val) {
  return (dispatch) => {
    // console.log("**updateActiveToolField")
    // console.log(val)
    dispatch({
      type: "ACTIVETOOL_FIELD_UPDATE",
      data: val,
    })
  }
}
export function resetTemplateDelete() {
  return (dispatch) => {
    // console.log("**resetTemplateDelete")
    dispatch({
      type: "PUBLISHER_TEMPLATE_DELETE_RESET",
      // data: val,
    })
  }
}

// export function loadPublisherTemplatesPromise() {
//   let cid = Cache.getItem("cid")
//   let apiName = "Storylavaweb"
//   let path = "admin/template/list"
//   new Promise(function (resolve, reject) {
//     Auth.currentAuthenticatedUser()
//       .then((user) => {
//         //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
//         let jwtToken = user.signInUserSession.accessToken.jwtToken
//         let options = {
//           headers: {
//             Authorization: jwtToken,
//           },
//           queryStringParameters: {
//             cid: cid,
//           },
//         }
//         API.get(apiName, path, options)
//           .then((response) => {
//             const data = response || {}

//             let result = data

//             console.log("actions - loadPublisherTemplates - API.get - result")
//             console.log(result)

//             return resolve(
//               dispatch({
//                 type: "PUBLISHER_FIELD_UPDATE",
//                 data: { prop: "templates", val: result },
//               })
//             )
//           })
//           .catch((error) => {
//             console.log(error.response)
//           })
//       })
//       .catch((error) => {
//         console.log(error)
//       }) //end get Auth token
//   })
// }

export function loadPublisherTemplates(redirect) {
  console.log("actions - loadPublisherTemplates - start")

  let cid = Cache.getItem("cid")

  console.log("actions - loadPublisherTemplates - cid")
  console.log(cid)

  let apiName = "Storylavaweb"

  let path = "admin/template/list"

  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_START",
    })

    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - loadPublisherTemplates - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
        }

        console.log(
          "actions - loadPublisherTemplates - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - loadPublisherTemplates - API.get - result")
              console.log(result)
              result = result.templates.map((template) => ({
                ...template,
                tags: template.tags ? template.tags : [],
              }))

              if (redirect) {
                dispatch({
                  type: "PUBLISHER_FIELD_UPDATE",
                  data: { prop: "templates", val: result },
                })
                dispatch({
                  type: "TEMPLATETRAY_FIELD_UPDATE",
                  data: true,
                })
                dispatch({
                  type: "TEMPLATE_CREATE_UPDATE_END",
                })

                return resolve(
                  dispatch(loadNewTemplate(redirect.id, redirect.context))
                )
              } else {
                dispatch({
                  type: "TEMPLATE_CREATE_UPDATE_END",
                })
                return resolve(
                  dispatch({
                    type: "PUBLISHER_FIELD_UPDATE",
                    data: { prop: "templates", val: result },
                  })
                )
              }
            })
            .catch((error) => {
              console.log("actions - loadPublisherTemplates - API.get - error")
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
        dispatch(logoutUser())
      }) //end get Auth token
  }
}

export function loadSocialUserTemplates(uid) {
  console.log("actions - loadUserTemplates - start")

  let cid = Cache.getItem("cid")
  let userSub = uid

  console.log("actions - loadUserTemplates - cid")
  console.log(cid)

  let apiName = "Storylavaweb"

  let path = "admin/template/list"

  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_START",
    })

    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - loadUserTemplates - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
            uid: userSub,
            mode: "userpublic",
          },
        }

        console.log(
          "actions - loadUserTemplates - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - loadUserTemplates - API.get - result")
              console.log(result)
              // result = result.map((template) => ({
              //   ...template,
              //   tags: template.tags ? template.tags : [],
              // }))

              dispatch({
                type: "TEMPLATE_CREATE_UPDATE_END",
              })
              dispatch({
                type: "SOCIAL_FIELD_UPDATE",
                data: { prop: "userdata", val: result.user },
              })
              return resolve(
                dispatch({
                  type: "SOCIAL_FIELD_UPDATE",
                  data: { prop: "usertemplates", val: result.templates },
                })
              )
            })
            .catch((error) => {
              console.log("actions - loadPublisherTemplates - API.get - error")
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
        dispatch(logoutUser())
      }) //end get Auth token
  }
}

export function loadSocialKeywordTemplates(keyword) {
  console.log("actions - loadSocialKeywordTemplates - start")

  let cid = Cache.getItem("cid")

  console.log("actions - loadSocialKeywordTemplates - cid")
  console.log(cid)

  let apiName = "Storylavaweb"

  let path = "admin/template/list"

  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_START",
    })

    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - loadSocialKeywordTemplates - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
            // uid: userSub,
            mode: "keywordpublic",
            keyword,
          },
        }

        console.log(
          "actions - loadSocialKeywordTemplates - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log(
                "actions - loadSocialKeywordTemplates - API.get - result"
              )
              console.log(result)
              // result = result.map((template) => ({
              //   ...template,
              //   tags: template.tags ? template.tags : [],
              // }))

              dispatch({
                type: "TEMPLATE_CREATE_UPDATE_END",
              })
              return resolve(
                dispatch({
                  type: "SOCIAL_FIELD_UPDATE",
                  data: { prop: "keywordtemplates", val: result.templates },
                })
              )
            })
            .catch((error) => {
              console.log(
                "actions - loadSocialKeywordTemplates - API.get - error"
              )
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        console.log("actions - loadSocialKeywordTemplates - outer - error")
        console.log(error)
        dispatch(logoutUser())
      }) //end get Auth token
  }
}

export function createTemplate(
  elements,
  stage,
  image,
  title,
  tags,
  division,
  context,
  elementsmode,
  userdetail
) {
  console.log("actions - createTemplate - start")

  let cid = Cache.getItem("cid")

  console.log("actions - createTemplate - cid")
  console.log(cid)
  console.log("actions - createTemplate - context")
  console.log(context)

  let apiName = "Storylavaweb"

  let path = "admin/template/save"

  let uniqueTags = []
  if (tags && tags.length > 0) {
    uniqueTags = [...new Set(tags)]
  }

  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_CREATE_UPDATE_START",
    })
    return Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - createTemplate - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken

        let userId = user.username

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            elements,
            stage,
            image,
            title,
            tags: uniqueTags,
            division,
            context,
            cid,
            uid: userId,
            mode: elementsmode,
            fname: userdetail.fname,
            lname: userdetail.lname,
          },
        }

        console.log(
          "actions - createTemplate - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        return new Promise((resolve) => {
          API.post(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - createTemplate - API.post - result")
              console.log(result)

              // navigate("/app/screener/")
              console.log(
                "actions - createTemplate - loadPublisherTemplates - pre"
              )
              console.log(result)
              $(`#templateModal`).modal("hide")

              resolve(
                dispatch({
                  type: "TEMPLATE_CREATE_UPDATE_COMPLETE",
                  data: result,
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        dispatch(logoutUser())
        console.log(error)
      }) //end get Auth token
  }
}

export function updateTemplate(
  id,
  title,
  tags,
  division,
  elements,
  image,
  fname,
  lname,
  stage
) {
  console.log("actions - updateTemplate - start")
  return (dispatch, getState) => {
    const { userdetail } = getState()

    let divisionVal = division ? division : "none"

    let cid = Cache.getItem("cid")

    let uniqueTags = []
    if (tags && tags.length > 0) {
      uniqueTags = [...new Set(tags)]
    }

    console.log("actions - updateTemplate - cid")
    console.log(cid)

    let apiName = "Storylavaweb"

    let path = "admin/template/update"

    dispatch({
      type: "TEMPLATE_EDIT_UPDATE_START",
    })
    return Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - updateTemplate - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken

        let userId = user.username

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            id,
            title,
            tags: uniqueTags,
            division: divisionVal,
            image,
            cid,
            uid: userId,
            stage,
          },
        }

        if (elements) {
          options.body.elements = elements
        }
        if (userdetail.fname !== fname) {
          options.body.fname = userdetail.fname
        }
        if (userdetail.lname !== lname) {
          options.body.lname = userdetail.lname
        }
        console.log(
          "actions - updateTemplate - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        return new Promise((resolve) => {
          API.post(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - updateTemplate - API.post - result")
              console.log(result)

              // navigate("/app/screener/")
              console.log(
                "actions - updateTemplate - loadPublisherTemplates - pre"
              )
              console.log(result)
              $("#pills-new-tab").tab("show") // Select tab by name
              $(`#templateModal`).modal("hide")
              $(`#templateEditModal`).modal("hide")

              dispatch({
                type: "TEMPLATE_EDIT_UPDATE_COMPLETE",
              })

              resolve(dispatch(loadPublisherTemplates()))
            })
            .catch((error) => {
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        dispatch(logoutUser())
        console.log(error)
      }) //end get Auth token
  }
}

export function removeTemplate(id) {
  console.log("actions - removeTemplate - start")

  let cid = Cache.getItem("cid")

  console.log("actions - removeTemplate - cid")
  console.log(cid)

  let apiName = "Storylavaweb"

  let path = "admin/template/remove"

  return (dispatch) => {
    dispatch({
      type: "TEMPLATE_EDIT_UPDATE_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - removeTemplate - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
            id,
          },
        }

        console.log(
          "actions - removeTemplate - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - removeTemplate - API.get - result")
              console.log(result)

              $(`#templateEditModal`).modal("hide")

              return resolve(
                dispatch({
                  type: "PUBLISHER_TEMPLATE_REMOVE",
                  data: { id },
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        dispatch(logoutUser())
        console.log(error)
      }) //end get Auth token
  }
}

export function sendUpload(file, pos, mode, tags) {
  console.log("**** sendUpload - file ****")
  console.log(file)
  if (file && pos) {
    // const fileList = [...e.target.files]
    // let itemsProcessed = 0

    // fileList.forEach((fileItem, i, array) => {
    console.log("sendUpload - file")
    console.log(file)
    // itemsProcessed++

    // const file = fileItem
    const fileExt = file.name.split(".").pop().toLowerCase()

    const fileId = uuidv4()

    const fileName = `${fileId}.${fileExt}`

    const fileSize = file.size

    let cid = Cache.getItem("cid")

    console.log("actions - sendUpload - cid")
    console.log(cid)

    let apiName = "Storylavaweb"

    let path = "admin/image/dataupload"

    return (dispatch) => {
      dispatch({
        type: "IMAGEUPLOAD_START",
      })
      Auth.currentAuthenticatedUser()
        .then((user) => {
          //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
          console.log(
            "actions - updateTemplate - Auth.currentAuthenticatedUser - user"
          )
          console.log(user)
          let jwtToken = user.signInUserSession.accessToken.jwtToken

          let userId = user.username

          let filePrefix
          if (mode === "user") {
            filePrefix = `u/${userId}`
          } else if (mode === "brand") {
            filePrefix = `c/${cid}`
          }

          let options = {
            headers: {
              Authorization: jwtToken,
            },
            queryStringParameters: {
              cid,
            },
            body: {
              fileId,
              fileExt,
              filePrefix,
              cid,
              uid: userId,
              tags,
              fileSize,
              mode,
            },
          }

          console.log(
            "actions - updateTemplate - Auth.currentAuthenticatedUser - options"
          )
          console.log(options)

          // dispatch({
          //   type: "IMAGEUPLOAD_FINISH",
          // })

          //begin promise
          new Promise((resolve) => {
            //START STORAGE.PUT
            Storage.put(`${filePrefix}/${fileName}`, file, {
              // contentType: "image/png",
              level: "public",
              acl: "public-read",
              // cacheControl: "no-cache",
              progressCallback(progress) {
                let progressNum = (progress.loaded / progress.total) * 100
                progressNum = Math.round(progressNum)
                // console.log(`Uploaded: ${progress.loaded / progress.total}`)
                console.log(`Uploaded**: ${progressNum}`)

                // progressString = progressString.toString()
                dispatch({
                  type: "IMAGEUPLOAD_PROGRESS",
                  data: { prop: pos, val: progressNum },
                })
              },
            })
              .then((result) => {
                console.log("***** sendUpload - uploading file")
                console.log(result)
                console.log("***** sendUpload -apiName")
                console.log(apiName)
                console.log("***** sendUpload -path")
                console.log(path)
                console.log("***** sendUpload -options")
                console.log(options)
                API.post(apiName, path, options)
                  .then((response) => {
                    const data = response || {}

                    let result2 = data

                    console.log("actions - sendUpload - API.post - result2")
                    console.log(result2)
                    // if (itemsProcessed === array.length) {
                    //   console.log(
                    //     "::::::: clearing form field :::::: saveimage"
                    //   )
                    //   dispatch({
                    //     type: "CLEAR_FORM_FIELD",
                    //     data: { prop: "saveimage" },
                    //   })
                    // }
                    return resolve(
                      dispatch({
                        type: "IMAGEUPLOAD_FINISH",
                        data: {
                          prop: pos,
                          val: 101,
                          loc: `${filePrefix}/${fileName}`,
                        }, //lazy way - setting val to 101 to reflect that thumbnails have completed
                      })
                    )
                  })
                  .catch((error2) => {
                    if (
                      error2 &&
                      error2.response &&
                      error2.response.status &&
                      (error2.response.status === 401 ||
                        error2.response.status === 403)
                    ) {
                      dispatch(logoutUser(unauthorizedMessage))
                    }
                    console.log(error2)
                  })
              })
              .catch((err) => console.log(err))
            //END STORAGE.PUT
          }) //end promise
        })
        .catch((error) => {
          dispatch(logoutUser())
          console.log(error)
        }) //end get Auth token
    }
    // }) //end foreach
  } //end if file.name
}

export function loadImageLibrary(context) {
  console.log("actions - loadImageLibrary - start")

  let cid = Cache.getItem("cid")

  console.log("actions - loadImageLibrary - cid")
  console.log(cid)

  let apiName = "Storylavaweb"

  let path = "admin/image/datalist"

  return (dispatch) => {
    dispatch({
      type: "LOADING_START",
    })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
        console.log(
          "actions - loadImageLibrary - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)

        let userId = user.username

        let jwtToken = user.signInUserSession.accessToken.jwtToken
        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
            uid: userId,
            context,
          },
        }

        console.log(
          "actions - loadImageLibrary - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve) => {
          API.get(apiName, path, options)
            .then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - loadImageLibrary - API.get - result")
              console.log(result)
              // result = result.map((image) => ({
              //   ...template,
              //   tags: template.tags ? template.tags : [],
              // }))

              let datacontext
              if (context === "user") {
                datacontext = "userimages"
              } else if (context === "brand") {
                datacontext = "brandimages"
              }
              return resolve(
                dispatch({
                  type: "PUBLISHER_FIELD_UPDATE",
                  data: { prop: datacontext, val: result },
                })
              )
            })
            .catch((error) => {
              console.log(error.response)
              if (
                error &&
                error.response &&
                error.response.status &&
                (error.response.status === 401 || error.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        return dispatch(logoutUser())
        console.log(error)
      }) //end get Auth token
  }
}

export function finishVideoPreview() {
  return (dispatch) => {
    dispatch({
      type: "SOCIALMODAL_FINISHED",
    })
  }
}
export function resetImageUpload() {
  return (dispatch) => {
    dispatch({
      type: "IMAGEUPLOAD_RESET",
    })
  }
}
export function sendUploadCloud(
  fileid,
  data,
  filename,
  optionStorage,
  attrs,
  webHostedImage,
  size
) {
  let cid = Cache.getItem("cid")

  console.log("actions - createTemplate - cid")
  console.log(cid)

  let apiName = "Storylavaweb"

  let path = "admin/export/save"

  return (dispatch) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833

        console.log(
          "actions - sendUploadCloud - Auth.currentAuthenticatedUser - user"
        )
        console.log(user)
        let jwtToken = user.signInUserSession.accessToken.jwtToken

        let userId = user.username

        const fileExt = filename.split(".").pop().toLowerCase()

        let options = {
          headers: {
            Authorization: jwtToken,
          },
          queryStringParameters: {
            cid,
          },
          body: {
            fileid,
            filename,
            cid,
            uid: userId,
            attrs,
            webHostedImage,
            fileExt,
          },
        }

        const pos = 0

        const optionsStorageNew = {
          level: "public",
          acl: "public-read",
          contentType: "image/jpeg",
          contentDisposition: "attachment",
          // cacheControl: "no-cache",
          // ContentEncoding: "base64",
          progressCallback(progress) {
            let progressNum = (progress.loaded / progress.total) * 100
            progressNum = Math.round(progressNum)
            // console.log(`Uploaded: ${progress.loaded / progress.total}`)
            console.log(`Uploaded**: ${progressNum}`)

            // progressString = progressString.toString()
            dispatch({
              type: "IMAGEUPLOAD_PROGRESS",
              data: { prop: pos, val: progressNum },
            })
          },
        }

        console.log(
          "actions - sendUploadCloud - Auth.currentAuthenticatedUser - options"
        )
        console.log(options)

        //begin promise
        new Promise((resolve, reject) => {
          Storage.put(`${user.username}/${filename}`, data, optionsStorageNew)
            .then((result) => {
              console.log("***** sendUpload - uploading file")
              console.log(result)

              API.post(apiName, path, options)
                .then((response) => {
                  const data2 = response || {}

                  let result2 = data2

                  console.log("actions - sendUploadCloud - API.post - result")
                  console.log(result2)

                  console.log("actions - sendUploadCloud - API.post - pre")
                  console.log(result2)
                  if (size === "preview") {
                    return resolve()
                  } else {
                    dispatch({
                      type: "IMAGEUPLOAD_FINISH",
                      data: { prop: pos, val: 100 },
                    })
                    return resolve(
                      dispatch({
                        type: "SOCIALMODAL_FINISHED",
                        data: result2,
                      })
                    )
                  }
                })
                .catch((error2) => {
                  console.log("actions - sendUploadCloud - API.post - catch")
                  console.log(error2.response)
                  if (
                    error2 &&
                    error2.response &&
                    error2.response.status &&
                    (error2.response.status === 401 ||
                      error2.response.status === 403)
                  ) {
                    dispatch(logoutUser(unauthorizedMessage))
                  }
                })
            })
            .catch((err) => {
              console.log("actions - sendUploadCloud - Storage.Put - catch")
              console.log(err)

              if (
                err &&
                err.response &&
                err.response.status &&
                (err.response.status === 401 || err.response.status === 403)
              ) {
                dispatch(logoutUser(unauthorizedMessage))
              }
            })
        }) //end promise
      })
      .catch((error) => {
        console.log(error)
        console.log("actions - sendUploadCloud - currentAuthUser - catch")

        if (
          error &&
          error.response &&
          error.response.status &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          dispatch(logoutUser(unauthorizedMessage))
        }
      }) //end get Auth token
  }
}

export function setSocialModal(bool) {
  return (dispatch) => {
    //begin promise
    new Promise((resolve) => {
      return resolve(
        dispatch({
          type: "SOCIALMODAL_REPLACE",
          data: bool,
        })
      )
    }) //end promise
  }
}

export function loadNewTemplate(id, context) {
  return (dispatch, getState) => {
    dispatch({
      type: "TEMPLATE_FETCH_START",
    })
    return new Promise((resolve, reject) => {
      const { publisher } = getState()
      console.log("----- ACTION publisher")
      console.log(publisher)

      console.log("loadNewTemplate - publisher")
      console.log(publisher)
      console.log("loadNewTemplate - props.context")
      console.log(context)
      console.log("loadNewTemplate - id")
      console.log(id)

      let newElements = []
      let loadedScenes = []
      let templateId = ""
      let newStage = []
      let sceneSelectedObj = {}
      //clear everything out
      // dispatch(updatePublisherField("elements", newElements))
      // dispatch(updatePublisherField("loadedScenes", loadedScenes))
      // dispatch(updatePublisherField("sceneSelected", sceneSelectedObj))

      // dispatch(updatePublisherField("templateSelected", templateId))
      // dispatch(updatePublisherField("stage", newStage))
      // dispatch(updatePublisherField("elementSelected", {}))
      // dispatch(updatePublisherField("mode", context))

      //IF ID IS PROVIDED
      if (id) {
        let sceneIndex
        let sceneSelectedId

        //START API

        let cid = Cache.getItem("cid")

        console.log("actions - loadNewTemplate - cid")
        console.log(cid)

        let apiName = "Storylavaweb"

        let path = "admin/template/single"

        Auth.currentAuthenticatedUser()
          .then((user) => {
            //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
            console.log(
              "actions - loadNewTemplate - Auth.currentAuthenticatedUser - user"
            )
            console.log(user)
            let jwtToken = user.signInUserSession.accessToken.jwtToken

            let userId = user.username

            let options = {
              headers: {
                Authorization: jwtToken,
              },
              queryStringParameters: {
                id,
                cid,
              },
              // body: {
              // },
            }

            console.log(
              "actions - loadNewTemplate - Auth.currentAuthenticatedUser - options"
            )
            console.log(options)

            API.get(apiName, path, options).then((response) => {
              const data = response || {}

              let result = data

              console.log("actions - loadNewTemplate - API.post - result")
              console.log(result)

              //START API RESULT
              const loadedTemplate = result
              const templateIndex = publisher.templates.findIndex(
                (item) => item.id === id
              )

              if (publisher.sceneSelected && publisher.sceneSelected.id) {
                sceneIndex = publisher.scene.findIndex(
                  (scene) => scene.id === publisher.sceneSelected.id
                )
                sceneSelectedId = publisher.sceneSelected.id
              } else {
                sceneIndex = 0
                sceneSelectedId = "scene1"
              }

              console.log("templateIndex")
              console.log(templateIndex)
              console.log("sceneSelectedId")
              console.log(sceneSelectedId)
              console.log("sceneIndex")
              console.log(sceneIndex)
              templateId = loadedTemplate.id
              newElements = loadedTemplate.elements.find(
                (scene) => scene.sceneid === sceneSelectedId
              ).data
              newStage = loadedTemplate.stage
              let newScene

              if (context === "video") {
                loadedScenes = loadedTemplate.scene
                newScene = loadedTemplate.scene[sceneIndex]
                sceneSelectedObj = {
                  sceneid: newScene.sceneid,
                  layerid: newScene.scenelayers[0].id,
                }
              }
              console.log("newElements")
              console.log(newElements)
              dispatch(updatePublisherField("elements", newElements))
              dispatch(updatePublisherField("loadedScenes", loadedScenes))
              dispatch(updatePublisherField("sceneSelected", sceneSelectedObj))

              dispatch(updatePublisherField("templateSelected", templateId))
              dispatch(updatePublisherField("stage", newStage))
              dispatch(updatePublisherField("elementSelected", {}))
              dispatch(updatePublisherField("mode", context))

              return resolve(
                dispatch({
                  type: "TEMPLATE_CREATE_UPDATE_COMPLETE",
                })
              )

              //END API RESULT
            }) //end API.post
          })
          .catch((error) => {
            dispatch(logoutUser())
            console.log(error)
          }) //end get Auth token

        //END API
      } else {
        if (context === "video") {
          navigate("/app/video/")
        } else if (context === "image") {
          navigate("/app/publisher/")
        }
        return resolve(
          dispatch({
            type: "TEMPLATE_FETCH_END",
          })
        )
      } //END if id provided
    }) //end promise
  }
  //TODO: confirm replacement via modal
}

export function setImageLibraryContext(context) {
  return (dispatch) => {
    dispatch({
      type: "IMAGELIBRARY_CONTEXT_REPLACE",
      data: context,
    })
  }
}

export function deleteImages(mode, ids) {
  console.log("**** deleteImage - mode ****")
  console.log(mode)
  console.log("**** deleteImage - ids ****")
  console.log(ids)
  if (mode && ids) {
    let cid = Cache.getItem("cid")

    console.log("actions - deleteImages - cid")
    console.log(cid)

    let apiName = "Storylavaweb"

    let path = "admin/image/dataremove"

    return (dispatch) => {
      dispatch({
        type: "IMAGEDELETE_START",
      })

      Auth.currentAuthenticatedUser()
        .then((user) => {
          //AWS4 signature doesnt work, use jwtToken instead. https://github.com/aws/aws-amplify/issues/833
          console.log(
            "actions - deleteImages - Auth.currentAuthenticatedUser - user"
          )
          console.log(user)
          let jwtToken = user.signInUserSession.accessToken.jwtToken

          let userId = user.username

          let filePrefix
          if (mode === "usermedia") {
            filePrefix = `u/${userId}`
          } else if (mode === "brandmedia") {
            filePrefix = `c/${cid}`
          }

          let options = {
            headers: {
              Authorization: jwtToken,
            },
            queryStringParameters: {
              cid,
            },
            body: {
              ids,
            },
          }

          console.log(
            "actions - deleteImages - Auth.currentAuthenticatedUser - options"
          )
          console.log(options)

          //begin promise
          new Promise((resolve) => {
            //START STORAGE.REMOVE

            API.post(apiName, path, options)
              .then((response) => {
                const data = response || {}

                let result2 = data

                console.log("actions - deleteImages - API.post - result2")
                console.log(result2)

                let fileList = []
                ids.forEach((id) => {
                  fileList.push(`${filePrefix}/${id}.jpg`)
                  fileList.push(`${filePrefix}/${id}-1000w.jpg`)
                  fileList.push(`${filePrefix}/${id}-1000w.webp`)
                  fileList.push(`${filePrefix}/${id}-100h.jpg`)
                  fileList.push(`${filePrefix}/${id}-100h.webp`)
                })

                console.log("actions - deleteImages - fileList")
                console.log(fileList)

                fileList.forEach((file, i) => {
                  Storage.remove(file)
                    .then((result) => {
                      if (i + 1 === fileList.length) {
                        dispatch({
                          type: "IMAGEDELETE_FINISH",
                        })
                        resolve(
                          setTimeout(function () {
                            dispatch({
                              type: "IMAGEDELETE_COMPLETE",
                            })
                          }, 5000)
                        )
                      }
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                }) //end fileList.forEach
              })
              .catch((error2) => {
                console.log(error2)
                if (
                  error2 &&
                  error2.response &&
                  error2.response.status &&
                  (error2.response.status === 401 ||
                    error2.response.status === 403)
                ) {
                  dispatch(logoutUser(unauthorizedMessage))
                }
              }) //end API.post

            //END STORAGE.REMOVE
          }) //end promise
        })
        .catch((error) => {
          console.log(error)
        }) //end get Auth token
    }
    // }) //end foreach
  } //end if id && mode
}
